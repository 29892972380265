/**
 * @file changelog.jsx
 * @description Changelog page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";

/**
 * @function ChangelogPage
 * @description Changelog page.
 * @returns {JSX.Element} Changelog page.
 */

function ChangelogPage() {
  return (
    <>
      <Meta
        title="Changelog"
        description="Get the latest news about Winter."
      />
      <Hero title="Changelog" subtitle="Get the latest news about Winter." />
      <div className="section">
        <div className="container">
          <div className="timeline is-centered">
            <header className="timeline-header">
              <span className="tag is-medium">
                Version 1.0.0
              </span>
            </header>
            <div className="timeline-item">
              <div className="timeline-marker is-primary"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag is-primary">1.0.1</span>{" "}
                  <time dateTime="2021-01-01T16:26:33+01:00">
                    1st January 2021
                  </time>
                </p>
                <p>
                  <span className="has-text-warning">&bull;</span> Fixed a style error with the forum categories.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangelogPage;
