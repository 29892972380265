/**
 * @file hero.jsx
 * @description Hero component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";

/**
 * @function Hero
 * @param {Object} props
 * @param {string} props.title
 * @param {string} [props.subtitle]
 * @param {string} [props.color]
 * @throws {Error} If the title prop is not set.
 * @returns {JSX.Element} Hero component.
 */

function Hero(props) {
  if (props.title === undefined) {
    throw new Error("The title prop is required for the Hero component.");
  }

  return (
    <section className={"hero " + (props.color ? props.color : "is-primary")}>
      <div className="hero-body">
        <div className="container">
          <h1 className="title">{props.title}</h1>
          {props.subtitle && <h2 className="subtitle">{props.subtitle}</h2>}
        </div>
      </div>
    </section>
  );
}

export default Hero;
