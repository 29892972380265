/**
 * @file downloadFile.js
 * @description This file contains the function to download the file from Appwrite Storage.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import { Client, Account, Storage, Databases } from "appwrite";

/**
 * @function downloadFile
 * @description This function downloads the file from Appwrite Storage.
 * @param {string} email The email address of the user.
 * @param {string} version The version of the file to download.
 * @returns {void}
 * @throws {Error} If the API call fails.
 */

function downloadFile(email, version) {
  const client = new Client();
  client
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT)
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT);

  const account = new Account(client);
  const storage = new Storage(client);
  const databases = new Databases(client);
  
  const getSession = account.getSession("current");
  getSession.then(
    (session) => {
      if (session.$id) {
        const createLog = databases.createDocument(
          "63e966940bd90dcc20c9",
          "63f1f66d2682fe3f22e8",
          "unique()",
          { email: email, date: new Date().toISOString(), version: version },
        );
        createLog.then(
          () => {
            const provideDownload = storage.getFileDownload(
              "63f1f54c806432c18068",
              version,
            );
            window.open(provideDownload, "_blank");
          },
          (error) => {
            console.log(error);
          },
        );
      }
    },
    (error) => {
      if (error.code === 401) {
        const createSession = account.createAnonymousSession();
        createSession.then(
          (session) => {
            const createLog = databases.createDocument(
              "63e966940bd90dcc20c9",
              "63f1f66d2682fe3f22e8",
              "unique()",
              {
                email: email,
                date: new Date().toISOString(),
                version: version,
              },
            );
            createLog.then(
              () => {
                const provideDownload = storage.getFileDownload(
                  "63f1f54c806432c18068",
                  version,
                );
                window.open(provideDownload, "_blank");
              },
              (error) => {
                console.log(error);
              },
            );
          },
          (error) => {
            console.log(error);
          },
        );
      }
    },
  );
}

export default downloadFile;
