/**
 * @file legalnotice.jsx
 * @description Legal notice page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";

/**
 * @function LegalNoticePage
 * @returns {JSX.Element} Legal notice page.
 */

function LegalNoticePage() {
  return (
    <>
      <Meta
        title="Legal Notice"
        description="Not for advertising purposes or support requests."
        noindex={true}
      />
      <Hero
        title="Legal Notice"
        subtitle="Not for advertising purposes or support requests."
      />
      <div className="section">
        <div className="container">
          <div className="content">
            <dl className="legal-notice">
              <dt>Name and Address</dt>
              <dd>
                Felix Wa&szlig;muth
                <br />
                Krummenseer Stra&szlig;e 23
                <br />
                12685 Berlin
                <br />
                Germany
              </dd>
              <dt>Email Address</dt>
              <dd>
                <a href="mailto:hello@felix-d1strict.de">
                  hello@felix-d1strict.de
                </a>
              </dd>
              <dt>Phone</dt>
              <dd>
                <a href="tel:+493054886709">+49 30 54886709</a>
              </dd>
              <dt>Responsible for Content</dt>
              <dd>
                Felix Wa&szlig;muth
                <br />
                Krummenseer Stra&szlig;e 23
                <br />
                12685 Berlin
                <br />
                Germany
              </dd>
              <dt>VAT ID</dt>
              <dd>DE 346 522 200</dd>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalNoticePage;
