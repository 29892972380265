/**
 * @file privacy.jsx
 * @description Privacy page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";

/**
 * @function PrivacyPage
 * @returns {JSX.Element} Privacy page.
 */

function PrivacyPage() {
  return (
    <>
      <Meta
        title="Privacy Policy"
        description="Your privacy is important to us."
        noindex={true}
      />
      <Hero
        title="Privacy Policy"
        subtitle="Your privacy is important to us."
      />
      <div className="section">
        <div className="container">
          <div className="content">
            <ol>
              <h3 className="title is-3">
                <li>
                  Introduction and contact details of the responsible person
                </li>
              </h3>
              <ol>
                <li>
                  We are pleased that you are visiting our website and thank you
                  for your interest. In the following, we inform you about the
                  handling of your personal data when using our website.
                  Personal data is any data that can be used to identify you
                  personally.
                </li>
                <li>
                  The controller of data processing on this website within the
                  meaning of the General Data Protection Regulation (GDPR) is
                  Felix Wa&szlig;muth, Krummenseer Stra&szlig;e 23, 12685
                  Berlin, Germany, Tel.: +49 30 54886709, e-mail:
                  hello@felix-d1strict.de. The controller is the natural or
                  legal person who alone or jointly with others determines the
                  purposes and means of the processing of personal data.
                </li>
                <li>
                  This website uses SSL or TLS encryption for security reasons
                  and to protect the transmission of personal data and other
                  confidential content (e.g. orders or inquiries to the person
                  <br />
                  responsible). You can recognize an encrypted connection by the
                  string &quot;https://&quot; and the lock symbol in your
                  browser line.
                </li>
              </ol>
              <h3 className="title is-3">
                <li>Data collection when visiting our website</li>
              </h3>
              <ol>
                <li>
                  During the mere informational use of our website, i.e. if you
                  do not register or otherwise transmit information to us, we
                  only collect data that your browser transmits to our server
                  (so-called &quot;server log files&quot;). When you call up our
                  website, we collect the following data, which is technically
                  necessary for us to display the website to you:
                  <ul>
                    <li>Our visited website</li>
                    <li>
                      Date and time at the time of access Amount of data sent in
                      bytes
                    </li>
                    <li>
                      Source/reference from which you reached the page Browser
                      used
                    </li>
                    <li>Operating system used</li>
                    <li>IP address used (if applicable: in anonymized form)</li>
                  </ul>
                  <br />
                  The processing is carried out in accordance with Art. 6 para.
                  1 lit. f DSGVO on the basis of our legitimate interest in
                  improving the stability and functionality of our website. A
                  transfer or other use of the data does not take place.
                  However, we reserve the right to check the server log files
                  retrospectively if there are concrete indications of illegal
                  use.
                </li>
              </ol>
              <h3 className="title is-3">
                <li>Cookies</li>
              </h3>
              <ol>
                <li>
                  In order to make visiting our website more attractive and to
                  enable the use of certain functions, we use cookies, i.e.
                  small text files that are stored on your end device. In some
                  cases, these cookies are automatically deleted again after
                  closing the browser (so-called &quot;session cookies&quot;),
                  in other cases, these cookies remain on your end device for
                  longer and allow page settings to be saved (so- called
                  &quot;persistent cookies&quot;). In the latter case, you can
                  find the storage period in the overview of the cookie settings
                  of your web browser. If personal data is also processed by
                  individual cookies used by us, the processing is carried out
                  in accordance with Art. 6 (1) lit. b DSGVO either for the
                  performance of the contract, in accordance with Art. 6 (1)
                  lit. a DSGVO in the case of consent given, or in accordance
                  with Art. 6 (1) lit. f DSGVO to protect our legitimate
                  interests in the best possible functionality of the website as
                  well as a customer-friendly and effective design of the page
                  visit. <br />
                  You can set your browser in such a way that you are informed
                  about the setting of cookies and can decide individually about
                  their acceptance or exclude the acceptance of cookies for
                  certain cases or in general. <br />
                  Please note that if you do not accept cookies, the
                  functionality of our website may be limited.
                </li>
              </ol>
              <h3 className="title is-3">
                <li>Contact</li>
              </h3>
              <ol>
                <li>
                  When contacting us (e.g. via contact form or e-mail), personal
                  data will be processed - exclusively for the purpose of
                  processing and responding to your request and only to the
                  extent necessary for this purpose. The legal basis for
                  processing this data is our legitimate interest in responding
                  to your request pursuant to Art. 6 (1) lit. f DSGVO. If your
                  contact aims at a contract, additional legal basis for the
                  processing is Art. 6 para. 1 lit. b DSGVO. Your data will be
                  deleted when the circumstances indicate that the matter in
                  question has been conclusively clarified and provided that
                  there are no statutory retention obligations to the contrary.
                </li>
              </ol>
              <h3 className="title is-3">
                <li>Data processing for order processing</li>
              </h3>
              <ol>
                <li>
                  Insofar as necessary for the processing of the contract for
                  delivery and payment purposes, the personal data collected by
                  us will be passed on to the commissioned transport company and
                  the commissioned credit institution in accordance with Art. 6
                  Para. 1 lit. b DSGVO.
                  <br />
                  If we owe you updates for goods with digital elements or for
                  digital products on the basis of a corresponding contract, we
                  will process the contact data (name, address, e-mail address)
                  provided by you when placing the order in order to inform you
                  personally about upcoming updates within the legally
                  stipulated period within the scope of our legal information
                  obligations pursuant to Art. 6 (1) lit. c DSGVO by suitable
                  means of communication (e.g. by post or e-mail). Your contact
                  data will be used strictly for the purpose of informing you
                  about updates owed by us and will only be processed by us for
                  this purpose to the extent necessary for the respective
                  information.
                  <br />
                  In order to process your order, we also work together with the
                  following service provider(s), which support us in whole or in
                  part in the execution of concluded contracts. Certain personal
                  data is transmitted to these service providers in accordance
                  with the following information.{" "}
                </li>
              </ol>

              <h3 className="title is-3">
                <li>Rights of the data subject</li>
              </h3>
              <ol>
                <li>
                  The applicable data protection law grants you the following
                  data subject rights (rights of information and intervention)
                  vis-&#224;-vis the controller with regard to the processing of
                  your personal data, whereby reference is made to the stated
                  legal basis for the respective exercise prerequisites:
                  <ul>
                    <li>Right to information according to Art. 15 DSGVO; </li>
                    <li>Right to rectification pursuant to Art. 16 GDPR; </li>
                    <li>Right to erasure pursuant to Art. 17 GDPR; </li>
                    <li>
                      Right to restriction of processing pursuant to Art. 18
                      GDPR; Right to information pursuant to Art. 19 GDPR;{" "}
                    </li>
                    <li>
                      Right to data portability according to Art. 20 DSGVO;{" "}
                    </li>
                    <li>
                      Right to withdraw consent granted pursuant to Art. 7(3)
                      GDPR; right to lodge a complaint pursuant to Art. 77 GDPR.
                    </li>
                  </ul>
                  <li>
                    RIGHT OF OBJECTION
                    <br />
                    IF WE PROCESS YOUR PERSONAL DATA IN THE CONTEXT OF A
                    BALANCING OF INTERESTS ON THE BASIS OF OUR OVERRIDING
                    LEGITIMATE INTEREST, YOU HAVE THE RIGHT TO OBJECT TO THIS
                    PROCESSING WITH EFFECT FOR THE FUTURE AT ANY TIME ON GROUNDS
                    ARISING FROM YOUR PARTICULAR SITUATION. IF YOU EXERCISE YOUR
                    RIGHT TO OBJECT, WE WILL STOP PROCESSING THE DATA CONCERNED.
                    HOWEVER, WE RESERVE THE RIGHT TO CONTINUE PROCESSING IF WE
                    CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR PROCESSING
                    THAT OVERRIDE YOUR INTERESTS, FUNDAMENTAL RIGHTS AND
                    FREEDOMS, OR IF THE PROCESSING IS FOR THE PURPOSE OF
                    ASSERTING, EXERCISING OR DEFENDING LEGAL CLAIMS.
                    <br />
                    IF WE PROCESS YOUR PERSONAL DATA FOR THE PURPOSE OF DIRECT
                    MARKETING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE
                    PROCESSING OF YOUR PERSONAL DATA FOR SUCH MARKETING. YOU MAY
                    EXERCISE THE OBJECTION AS DESCRIBED ABOVE.
                    <br />
                    IF YOU EXERCISE YOUR RIGHT TO OBJECT, WE WILL STOP
                    PROCESSING THE DATA CONCERNED FOR DIRECT MARKETING PURPOSES.
                  </li>
                  <h3 className="title is-3">
                    <li>Duration of the storage of personal data</li>
                  </h3>
                  <ol>
                    <li>
                      The duration of the storage of personal data is determined
                      on the basis of the respective legal basis, the purpose of
                      processing and - if relevant - additionally on the basis
                      of the respective statutory retention period (e.g.
                      retention periods under commercial and tax law).
                      <br />
                      When processing personal data on the basis of explicit
                      consent pursuant to Art. 6 (1) a DSGVO, this data is
                      stored until the data subject revokes his or her consent.
                      <br />
                      If there are statutory retention periods for data that is
                      processed within the scope of legal or quasi-legal
                      obligations on the basis of Art. 6 Para. 1 lit. b DSGVO,
                      this data will be routinely deleted after expiry of the
                      retention periods, insofar as it is no longer required for
                      the fulfillment or initiation of a contract and/or we do
                      not have a legitimate interest in continuing to store it.
                      <br />
                      When processing personal data on the basis of Art. 6(1)(f)
                      DSGVO, this data is stored until the data subject
                      exercises his or her right to object pursuant to Art.
                      21(1) DSGVO, unless we can demonstrate compelling
                      legitimate grounds for the processing which override the
                      interests, rights and freedoms of the data subject, or the
                      processing serves to assert, exercise or defend legal
                      claims.
                      <br />
                      When processing personal data for the purpose of direct
                      marketing on the basis of Art. 6 (1) lit. f DSGVO, this
                      data is stored until the data subject exercises his or her
                      right to object pursuant to Art. 21 (2) DSGVO.
                      <br />
                      Unless otherwise stated in the other information in this
                      statement about specific processing situations, stored
                      personal data will otherwise be deleted when it is no
                      longer necessary for the purposes for which it was
                      collected or otherwise processed.
                    </li>
                  </ol>
                </li>
              </ol>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPage;
